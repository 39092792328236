// import './styles.css'

export default function init({ onAuthed, sparkyFramework }) {

  return function tsgLoad() {
    return {
      navigation: [
        {
          title: 'CIE Onboard Dev',
          key: 'cie_onboarding_dev',
          path: '/cie_onboarding_dev',
          hidden: true,
          contentClassName: 'panwds-tw3 panwds-unset-reboot sparky-dark-only',
          component: () => import('./components/BlueBird/CIEOnboardingDev')
        }
      ]
    }
  }

};

export const exposes = {
  CIEComponents: {
    ProfileSelect: () => import('./components/BlueBird/CIEProfileSelect'),
  }
}

export { default as extensions } from './extensions'
