import { SPARKY_NAV3_LEFT_NAV_EXTPOINT } from '@sparky/framework/extpoints'
// import { HomeIcon } from '@panwds/icons'

export default {
  point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  configs: [
    // {
    //   id: 'sparky.nav.left.onboarding-dev',
    //   config: {
    //     navigation: () => [
    //       {
    //         key: 'onboarding-dev',
    //         title: 'CIE Onboard Dev',
    //         path: '/cie_onboarding_dev',
    //         avail: () => true,
    //         icon: HomeIcon,
    //       }
    //     ],
    //   }
    // }
  ]
}
