import { SPARKY_NAV3_ROUTES_EXTPOINT } from '@sparky/framework/extpoints'
// import { getFrameworkVars, getMicroAppVars } from "@sparky/framework";

export default {
  point: SPARKY_NAV3_ROUTES_EXTPOINT,
  configs: [
    {
      id: 'sparky.nav.routes.onboarding-dev',
      config: {
        path: '/cie_onboarding_dev_v3',
        replacePaths: '/cie_onboarding_dev',
        // stateLoaders: ['auth'],
        contentClassName: 'panwds-tw3 panwds-unset-reboot sparky-dark-only',
        component: () => import('../components/BlueBird/CIEOnboardingDev'),
        mapStateToProps: () => ({}),
        // mapStateToProps: (props) => ({
        //   ...props,
        //   routes: [{
        //     key: "cie_onboarding_dev",
        //     title: 'CIE Onboard Dev',
        //     path: '/cie_onboarding_dev',
        //     noRBACheck: true,
        //   }],
        //   vars: getMicroAppVars(),
        //   frameworkVars: getFrameworkVars(),
        // }),
        // isAllowed: () => true
      }
    }
  ]
}
